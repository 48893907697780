var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('b-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleAddRow($event)}}},[_c('validation-observer',{ref:"simpleRules"},[(_vm.isSideDataLoading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"height":"400px"}},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_c('div',{staticClass:"m-2"},[_c('b-row',{staticClass:"d-flex justify-content-between w-100"},[_c('b-col',{staticClass:"font-weight-bolder",attrs:{"md":"12"}},[_c('h3',{staticClass:"font-weight-bolder",staticStyle:{"font-size":"18px"}},[_vm._v(" Page Content ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input"}},[_vm._v("Title"),_c('span',{staticClass:"font-weight-bold text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Title","maxlength":"50"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',[_c('label',{attrs:{"for":"input"}},[_vm._v("Type"),_c('span',{staticClass:"font-weight-bold text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"placeholder":"Select Type","label":"name","options":_vm.typeOptions},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"input"}},[_vm._v("Page Link"),_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Page Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Page Link","maxlength":"50"},model:{value:(_vm.pagelink),callback:function ($$v) {_vm.pagelink=$$v},expression:"pagelink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"content"}},[_vm._v("Add Content"),_c('span',{staticClass:"text-danger font-weight-bold"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required","name":"Add Content"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticStyle:{"margin-top":"73px"},attrs:{"md":"12"}},[_c('h3',{staticClass:"font-weight-bolder",staticStyle:{"font-size":"18px"}},[_vm._v(" SEO Field ")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"input"}},[_vm._v("Title")]),_c('validation-provider',{attrs:{"rules":"required","name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Title","maxlength":"50"},model:{value:(_vm.seoTitle),callback:function ($$v) {_vm.seoTitle=$$v},expression:"seoTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"input"}},[_vm._v("Meta Description")]),_c('validation-provider',{attrs:{"rules":"required","name":"Meta Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Description","maxlength":"150"},model:{value:(_vm.metaDesc),callback:function ($$v) {_vm.metaDesc=$$v},expression:"metaDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"input"}},[_vm._v("Keywords")]),_c('validation-provider',{attrs:{"rules":"required","name":"Keywords"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Keywords","maxlength":"100"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"input"}},[_vm._v("Meta Image")]),_c('b-form-file',{staticClass:"d-flex",attrs:{"id":"file-default","label":"left"},on:{"input":function($event){return _vm.uploadDoc($event, 'image', 'MetaImage')}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-start mt-2",attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary","disabled":_vm.flag},on:{"click":function($event){return _vm.handleAddRow()}}},[_vm._v(" "+_vm._s(_vm.ifEdit == true ? "Update" : "Save")+" ")]),_c('b-button',{attrs:{"type":"reset","variant":"primary"},on:{"click":_vm.Close}},[_vm._v(" Cancel ")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }