<template>
  <div>
    <b-card no-body class="mb-0">
      <b-form @submit.prevent="handleAddRow($event)" ref="myForm">
        <validation-observer ref="simpleRules">
          <div
            v-if="isSideDataLoading"
            style="height: 400px"
            class="d-flex justify-content-center align-items-center"
          >
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div class="m-2" v-else>
            <b-row class="d-flex justify-content-between w-100">
              <b-col md="12" class="font-weight-bolder">
                <h3 class="font-weight-bolder" style="font-size: 18px">
                  Page Content
                </h3>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="input"
                    >Title<span class="font-weight-bold text-danger"
                      >*</span
                    ></label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Title"
                  >
                    <b-form-input
                      v-model="title"
                      placeholder="Title"
                      maxlength="50"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <label for="input">Type<span class="font-weight-bold text-danger">*</span></label>
                  <v-select
                    v-model="type"
                    placeholder="Select Type"
                    label="name"
                    :options="typeOptions"
                  />

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="input"
                    >Page Link<span class="text-danger font-weight-bold">*</span></label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Page Link"
                  >
                    <b-form-input
                      v-model="pagelink"
                      placeholder="Page Link"
                      maxlength="50"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="content"
                    >Add Content<span class="text-danger font-weight-bold"
                      >*</span
                    ></label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Add Content"
                  >
                    <b-form-textarea
                      v-model="content"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" style="margin-top: 73px">
                <h3 class="font-weight-bolder" style="font-size: 18px">
                  SEO Field
                </h3>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="input" class="font-weight-bold">Title</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Title"
                  >
                    <b-form-input
                      v-model="seoTitle"
                      placeholder="Title"
                      maxlength="50"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="input" class="font-weight-bold"
                    >Meta Description</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Meta Description"
                  >
                    <b-form-input
                      v-model="metaDesc"
                      placeholder="Description"
                      maxlength="150"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="input" class="font-weight-bold">Keywords</label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Keywords"
                  >
                    <b-form-input
                      v-model="keyword"
                      placeholder="Keywords"
                      maxlength="100"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label for="input" class="font-weight-bold">Meta Image</label>
                    <b-form-file
                      id="file-default"
                      @input="uploadDoc($event, 'image', 'MetaImage')"
                      v-model="image"
                      label="left"
                      class="d-flex"
                    >
                    </b-form-file>
                </b-form-group>
              </b-col>
              <b-col md="12" class="d-flex justify-content-start mt-2">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="handleAddRow()"
                  :disabled="flag"
                >
                  {{ ifEdit == true ? "Update" : "Save" }}
                </b-button>
                <b-button type="reset" variant="primary" @click="Close">
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </b-form>
    </b-card>
  </div>
</template>
  <script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BFormFile,
  BButton,
  BForm,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "@/component/axios";
import { quillEditor } from "vue-quill-editor";
import vSelect from 'vue-select'
import { required } from "@validations";




export default {
  components: {
    BCard,
    BSpinner,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    quillEditor,
    BFormTextarea,
    vSelect
  },
  data() {
    return {
      required,
      snowOption: {
        theme: "snow",
      },
      content: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      data: [],
      title: "",
      pagelink: "",
      isSideDataLoading: false,
      content: "",
      seoTitle: "",
      keyword: "",
      image: "",
      metaDesc: "",
      flag: false,
      ifEdit: false,
      id:null,
      type:'',
      typeOptions:[
        {name:'Terms & Conditions',value:'terms-conditions'}
      ]
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.editor.quill.root.style.height = '100%';
    // });
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}cmspage/show/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data.Cmspage);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.title=item.title;
      this.pagelink=item.link;
      this.content=item.content;
      this.seoTitle=item.metatitle;
      this.metaDesc=item.metadescription;
      this.keyword=item.keyword;
      this.image=item.metaimage;
      let arr = this.typeOptions.filter(inner=> inner.value == item.type)
      console.log(arr)
      this.type = arr.length > 0 ? arr[0] :'' ;
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    async created() {
      const id = this.$route.params.id;

      await axios({
        method: "GET",
        url: `${this.baseApi}cmspage/show/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          let data = response?.data.data;
          // console.log(data,"data here")
          this.image = data.image;
          this.id = data.id;
        })
        .catch((error) => {
          console.error("Error fetching CMS data:", error);
        });
    },
    handleAddRow() {
  this.$refs.simpleRules.validate().then((success) => {
    if (!success) {
      this.$swal({
        title: "Warning",
        timer: 5000,
        text: "Please fill in all fields",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      return;
    }
    const customerData = {
      title: this.title,
      link: this.pagelink,
      content: this.content,
      metatitle: this.seoTitle,
      metadescription: this.metaDesc,
      keyword: this.keyword,
      metaimage: this.image,
      slug: this.convertToSlug(this.title),
      type: this.type ? this.type.value :''   
    };

    const url = this.ifEdit ? `${this.baseApi}cmspage/update/${this.$route.params.id}` : `${this.baseApi}cmspage/add`;
    const method = this.ifEdit ? "put" : "post";

    axios({
      method: method,
      url: url,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      data: JSON.stringify(customerData),
    })
    .then((response) => {
      this.$swal({
        title: "Submitted",
        text: this.ifEdit ? "CMS Page Updated Successfully" : "CMS Created Successfully",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      this.$forceUpdate();
      this.$router.push('/cms')
    })
    .catch((error) => {
      this.$swal({
        title: "Error!",
        text: `${error}`,
        icon: "error",
        timer: 5000,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    });
  });
},
    
    async uploadDoc(event, variable, name) {
      this.flag = true;
      const formData = new FormData();
      formData.append("url", event);
      formData.append("type", "image");
      await axios({
        method: "POST",
        url: `${this.baseApi}attachments/uploadattachment`,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
      }).then(async (response) => {
        //   console.log(response.data.data.url)
        //   let data = {
        //     url: response.data.data.url,
        //     admin_id: JSON.parse(localStorage.getItem('userData')).id,
        //     status: 1
        //   }
        this[variable] = response.data.data.url;
        this.flag = false;
      });
    },
    Close() {
      this.$router.push({ path: "/cms" });
      // this.Addsupple = false;
      this.openModel = false;
    },
    
  },
};
</script>
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
  